
import { Watch, Mixins, Prop, Component, Vue, Inject, InjectReactive } from "vue-property-decorator";
import ProductOne from './productOne.vue';
import { presCategoryTypeList } from "@/api/prescriptionDb"
import { cloneDeep } from "lodash";


@Component({
    components:{
        ProductOne
    }
})
export default class ProProduct extends Vue {
    @Prop() readonly currentTab: number | string
    @InjectReactive() pageCurrentTab!: string|number;
    @InjectReactive() merchantId!: string|number;
    loading = false;
    list = [];

    @Watch("list", { deep: true })
    forceUpdate(value) {
        this.$forceUpdate();
    }

    // tab激活再调接口
    @Watch("currentTab", { immediate: true })
    async getHomeProductList(value) {
        if (!value || value != 2) return;
        this.loading = true;
        try {
            const { data } = await presCategoryTypeList({ categoryType: 1, parentId: value, merchantId: this.merchantId, isEnable: -1, ownerType: this.merchantId ? 1 : 0 });
            const list = data || [];
            this.list = list;
            list.forEach((item, index) => {
                item.status = !!item.status;
                presCategoryTypeList({ categoryType: 2, parentId: item.id, merchantId: this.merchantId, isEnable: -1 }).then((res) => {
                    this.$set(this.list, index, { ...this.list[index], list: (res.data || []) })
                })
            })
        } catch (error) {
            console.log(error);
        } finally{
            this.loading = false;
        }
    }
}
