
import { Watch, Emit, Prop, Component, Vue, Ref, InjectReactive } from "vue-property-decorator";
// @ts-ignore
import disableIcon from "@/assets/icon/disabled.png";
// @ts-ignore
import enableIcon from "@/assets/icon/enable.png";

import RichText from "@/components/rich-text.vue";
import AddProductType from "./addProductType.vue";
import UploadImg from "@/components/uploadImg.vue";

import { saveOrUpdatePresCategory, getCategoryDetail } from "@/api/prescriptionDb";

import { cloneDeep } from "lodash";

const jjDefaultImg = "https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/20231214095535jjcp.png";
const xmDefaultImg = "https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/20231214095607xm.png";
const qtDefaultImg = "https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/20231214113149at-ts.png";

@Component({
    components: {
        RichText,
        AddProductType,
        UploadImg
    }
})
export default class ProductOne extends Vue {
    @Ref("validateForm") readonly validateForm!: VForm;
    @Ref("AddProductType") readonly addProductTypeRef!: AddProductType;
    @Prop({ default: () => ({}) }) readonly productInfo: any;
    @InjectReactive() readonly deviceTypeOptions!: Array<any>;
    @InjectReactive() pageCurrentTab!: Object;
    @InjectReactive() merchantId!: string|number;
    productTypeOptions = [{ label: "项目产品库", value: 1 }, { label: "居家产品库", value: 2 }, { label: "其他库", value: 3 }]

    disableIcon = disableIcon;
    enableIcon = enableIcon;

    defaultImg = {
        1: xmDefaultImg,
        2: jjDefaultImg,
        3: qtDefaultImg,
    }

    dialogVisible = false;
    btnLoading = false;

    // 修改产品类别的 form
    productTypeData = {
        name: "",
    }

    type = "add" // add or edit
    modifyKey = "name" // name or content

    //  修改产品名称的 form
    productFormData = {
        id: undefined,
        parentId: undefined,
        name: "",
        productRemark: "",
        effect: "",
        docSource: "",
        img: [],
        status: true,
    }

    rules = {
        name: { required: true, validator: this.validatorProductName, message: '请输入产品名称'},
        effect: { required: true, validator: this.validatorGx, message: '请输入产品功效，30-400字'},
        img: { required: true, type: "array", message: "请上传图片" }
    }

    // el-switch 开关，必须 boolean; 0/1 需要转boolean在使用
    switchFlag = true;

    // 二级 设备类型名称
    get categoryTypeName() {
        const option = this.productTypeOptions.find((item) => item.value == this.productInfo.parentId)
        return option?.label;
    }

    get dialogTitle() {
        if (this.modifyKey == "name") {
            return this.productFormData?.id ? "编辑产品" : " 新增产品"
        }
        return this.productFormData?.name;
    }

    get deviceTypeDesc() {
        return this.deviceTypeOptions[1]?.deviceClassName;
    }

    get deviceClassDesc() {
        const options = this.deviceTypeOptions[1]?.subClassList;
        return options?.find((el) => el.id == this.productInfo?.deviceClassId)?.deviceClassName;        
    }

    validatorProductName(rule, value, callback) {
        const _value = value?.trim();
        _value ? callback() : callback(new Error())
    }
    validatorGx(rule, value, callback) {
        const _value = value?.trim();
        if (_value?.length >= 30 && _value?.length <= 400) {
            callback();
        }else {
            callback(new Error())
        }
    }

    //  编辑类型
    editProductType() {
        this.addProductTypeRef.dialogFormVisible = true;
        this.productTypeData = cloneDeep(this.productInfo);
    }

    productRemarkLoading = false;
    /**
     * @description 新增/编辑 产品名称或者内容
     * @param type 
     */
    addProductRow(modifyKey = "name", type = "add", product: any = {}) {
        const row = cloneDeep(product)
        this.dialogVisible = true;
        this.modifyKey = modifyKey;
        this.type = type;
        if (this.modifyKey == "productRemark") {
            this.productRemarkLoading = true;
            getCategoryDetail(product.id).then((res) => {
                this.productFormData = { ...row, id: row.id, status: row.id ? Boolean(row.status) : true, productRemark: res.data?.productRemark, img: row.img ? [{ url: row.img }] : [] };
            }).finally(() => {
                this.productRemarkLoading = false;
            })
        } else {
            this.productFormData = { ...row, id: row.id, status: row.id ? Boolean(row.status) : true, img: row.img ? [{ url: row.img }] : [] };
        }
    }

    dialogClose() {
        this.dialogVisible = false;
        this.productFormData.productRemark = "";
        this.productFormData.img = [];
        this.validateForm?.resetFields();
    }
    dialogConfirm() {
        this.validateForm?.validate().then(() => {
            const params = cloneDeep(this.productFormData);
            params.categoryType = this.productInfo?.categoryType + 1;
            params.sort = 0;
            params.parentId = this.productInfo?.id;
            params.status = Number(params.status);
            params.majorName = params.name;
            params.deviceClassId = this.productInfo.deviceClassId;
            params.img = params.img[0]?.url;
            Object.assign(params, { merchantId: this.merchantId, ownerType: this.merchantId ? 1: 0 })
            this.btnLoading = true;
            saveOrUpdatePresCategory(params).then(() => {
                this.refreshProductList();
                this.dialogClose();
            }).finally(() => {
                this.btnLoading = false;
            })
        })
    }

    // 触发列表刷新
    @Emit("refreshProductList")
    refreshProductList() {
        return this.productInfo.parentId;
    }
}
